import { StatsCard } from 'components/EventOverview';
import UserDashboard from 'components/DashboardLayout/UserDashboard';
import { DownloadIcon, DollarRoundIcon } from 'components/icons';
import { Box, Heading, Select, Button, DateInput } from 'grommet';
import React from 'react';
import SmartTable from 'components/SmartTable';

const dateFormat = new Intl.DateTimeFormat(undefined, {
  month: 'short',
  day: 'numeric'
});

function reports() {
  const [filter, setFilter] = React.useState('All Time');
  const [dateRange, setDateRange] = React.useState([
    '2020-07-31T15:27:42.920Z',
    '2020-08-07T15:27:42.920Z'
  ]);

  const onChange = (event: { value: string[] }) => {
    const nextValue = event.value;
    setDateRange(nextValue);
  };
  const tableColumns = [
    {
      key: 'createdAt',
      label: 'DATE'
    },
    {
      key: 'revenue',
      label: 'REVEMUE'
    },
    {
      key: 'ticketsNo',
      label: 'TICKESTNO',
      thStyle: {
        textAlign: 'right'
      }
    },
    {
      key: 'type',
      label: 'TICKET TYPE'
    },
    {
      key: 'attendee',
      label: 'ATTENDEE'
    }
  ];
  return (
    <UserDashboard>
      <Box pad="24px 0px" width={{ width: '100%', max: '1000px' }} margin={'0 auto'}>
        <Box direction="row" justify="between" align="center">
          <Heading level={'4'}>Finance Summary</Heading>
          <Box direction="row" align="center">
            <Select
              onChange={({ option }) => setFilter(option)}
              options={['Today', 'Current Week', 'Current Month', 'Custom Date', 'All Time']}
              value={filter}
              size="medium"
              style={{ marginRight: '10px' }}
            />
            {filter === 'Custom Date' && (
              <DateInput
                style={{
                  height: '48px',
                  background: 'transparent',
                  border: '1px solid #DCDCDE',
                  margin: '0px 10px'
                }}
                value={dateRange}
                buttonProps={{
                  label: `${dateFormat.format(new Date(dateRange[0]))} - ${dateFormat.format(
                    new Date(dateRange[1])
                  )}`
                }}
                onChange={onChange}
              />
            )}
            <Button
              margin={{ left: '10px' }}
              style={{ height: '48px', borderRadius: '100px' }}
              secondary
              icon={<DownloadIcon />}
              label="Download report"
            />
          </Box>
        </Box>
        <StatsCard width={{ width:"100%"}} icon={<DollarRoundIcon />} title="Total Revenue">
          <Heading level={'4'}>400</Heading>
        </StatsCard>

        <SmartTable data={[]} columns={tableColumns} hideSN />
      </Box>
    </UserDashboard>
  );
}

reports.propTypes = {};

export default reports;
