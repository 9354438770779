import React, { SVGProps } from 'react';

const DownloadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.5899 6.5H9.99988V1.5C9.99988 0.95 9.54988 0.5 8.99988 0.5H4.99988C4.44988 0.5 3.99988 0.95 3.99988 1.5V6.5H2.40988C1.51988 6.5 1.06988 7.58 1.69988 8.21L6.28988 12.8C6.67988 13.19 7.30988 13.19 7.69988 12.8L12.2899 8.21C12.9199 7.58 12.4799 6.5 11.5899 6.5ZM-0.00012207 16.5C-0.00012207 17.05 0.449878 17.5 0.999878 17.5H12.9999C13.5499 17.5 13.9999 17.05 13.9999 16.5C13.9999 15.95 13.5499 15.5 12.9999 15.5H0.999878C0.449878 15.5 -0.00012207 15.95 -0.00012207 16.5Z"
      fill="#3B3F49"
    />
  </svg>
);
export default DownloadIcon;
